// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import 'mixin.scss';

@font-face {
	font-family: 'Moul';
	src: url(../fonts/Moul.ttf);
}

@font-face {
	font-family: 'english';
	src: url(../fonts/Abel-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'khmer bold';
	src: url(../fonts/Kantumruy-Bold.ttf) format('truetype');
}

@font-face {
	font-family: 'khmer regular';
	src: url(../fonts/Kantumruy-Regular.ttf) format('truetype');
}

@font-face {
	font-family: 'khmer light';
	src: url(../fonts/Kantumruy-Light.ttf) format('truetype');
}

body {
	font-family: 'khmer regular', 'english' !important;
}

.fullscreen .react-images__blanket {
	z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
	margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
	.ps__rail-y {
		&:hover > .ps__thumb-y,
		&:focus > .ps__thumb-y,
		&.ps--clicking .ps__thumb-y {
			background-color: $grey500;
			width: 5px;
		}
	}
	.ps__thumb-y {
		background-color: $grey500;
		border-radius: 6px;
		width: 5px;
		right: 0;
	}
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
	&.ps--active-y > .ps__rail-y {
		width: 5px;
		background-color: transparent !important;
		z-index: 999;
		&:hover,
		&.ps--clicking {
			width: 5px;
			background-color: transparent;
		}
	}
	&.ps--scrolling-y > .ps__rail-y,
	&.ps--scrolling-x > .ps__rail-x {
		opacity: 0.4;
		background-color: transparent;
	}
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
	50% {
		transform: translateY(-40px);
	}
	100% {
		transform: translateY(0px);
	}
}

@keyframes blink {
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes bounce {
	0%,
	20%,
	53%,
	to {
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		transform: translateZ(0);
	}
	40%,
	43% {
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		transform: translate3d(0, -5px, 0);
	}
	70% {
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		transform: translate3d(0, -7px, 0);
	}
	80% {
		transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		transform: translateZ(0);
	}
	90% {
		transform: translate3d(0, -2px, 0);
	}
}

@keyframes slideY {
	0%,
	50%,
	100% {
		transform: translateY(0px);
	}
	25% {
		transform: translateY(-10px);
	}
	75% {
		transform: translateY(10px);
	}
}

@keyframes slideX {
	0%,
	50%,
	100% {
		transform: translateX(0px);
	}
	25% {
		transform: translateX(-10px);
	}
	75% {
		transform: translateX(10px);
	}
}

.container {
	padding: 10px 15px;
	max-width: 800px;
	padding-bottom: 50px;
	margin: 30px auto;
}

.box {
	border: 1px solid #f4eeee;
	border-radius: 16px;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
		rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	padding: 15px;
	padding-bottom: 5px;
	margin-bottom: 15px;
}

.logo {
	width: 100%;
	margin-bottom: 30px;
}

.font-weight-bold {
	font-weight: 600;
}

.form-label {
	font-family: 'khmer regular', 'english';
}

#components-layout-demo-custom-trigger .trigger {
	padding: 0 24px;
	font-size: 18px;
	line-height: 64px;
	cursor: pointer;
	transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
	color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
	height: 32px;
	margin: 16px;
	background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
	background: #fff;
}

.page-info {
    background-color: $commonBackground !important;
    padding: 15px !important;
    border-radius: 10px !important;
    margin-bottom: 15px !important;
	display: flex;
    justify-content: space-between;
    align-items: center;
    // font-family: 'Moul';
	
	.title {
		font-size: 18px !important;
		font-weight: bold !important;
		margin: 0 !important;
	}
}

.ant-message {
	z-index: 99999 !important;
}

.form-inline {
	.inline-input {
		border: none;
		padding: 0;
		margin-top: 10px;

		&.ant-input:focus {
			box-shadow: none;
		}

		&:not(.ant-select-customize-input) .ant-select-selector {
			border: none;
			padding-left: 0;

			&:focus {
				box-shadow: none;
			}
		}

		&.ant-picker {
			width: 100%;
		}
	}

	

	.inline-select {
		border: none;
		padding: 0;

		&.ant-input:focus {
			box-shadow: none;
		}

		&:not(.ant-select-customize-input) .ant-select-selector {
			border: none;
			padding-left: 0;

			&:focus {
				box-shadow: none;
			}
		}
	}

	.inline-textarea {
		margin-top: 10px;
		margin-bottom: 25px;

		textarea {
			border: none;
			padding: 0;
			width: calc(100% - 10px);

			&.ant-input:focus {
				box-shadow: none;
			}
		}
	}

	.inline-value {
		margin-top: 10px;
		margin-bottom: 25px;
	}

	.input-spacing {
		margin-bottom: 30px;
	}

	.ant-form-item-control-input {
		border-bottom: 1px solid #d9d9d9;
		width: calc(100% - 10px);
	}

	.no-border {
		.ant-form-item-control-input {
			border-bottom: none;
		}
	}
	
	.ant-select-selector {
		font-size: 13px;
		border-color: transparent !important;
		box-shadow: rgba(255, 255, 255, 0) !important;
	}

	.center {
		justify-content: center;
		align-items: center;
		display: flex;
	}
}

.ant-page-header-heading {
	width: 100%;
}

.pay {
	.logo-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.pay-logo {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.kh-moul {
		font-family: 'Moul' !important;
	}

	.center {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.text-center {
		text-align: center;
	}

	.school-name {
		
	}

	.label {
		font-weight: bold;
	}
}

.font-large {
	font-size: 20px;
}

.mr-1 {
	margin-right: 5px;
}

.mr-2 {
	margin-right: 10px;
}

.mr-3 {
	margin-right: 15px;
}

.mt-1 {
	margin-top: 5px;
}

.mt-2 {
	margin-top: 10px;
}

.mt-3 {
	margin-top: 15px;
}

.mt-4 {
	margin-top: 20px;
}

.mt-5 {
	margin-top: 25px;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.pt-0 {
	padding-top: 0 !important;
}

.inline {
	display: flex;
	align-items: center;
}

.inline-center {
	display: flex;
	justify-content: center;
}

.d-flex {
	display: flex !important;
}

.justify-content-between {
	justify-content: space-between !important;
}

.ant-row {
	&.item {
		padding-top: 10px;
		padding-bottom: 10px;
		border: 1px dashed #ccc;
	}
}